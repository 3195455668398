import { headerItem } from '@/utils/table'
import BadgeLogicStatus from '@/components/apps/badges/badge-logic-status/index.vue'
import ItemActions from './item-actions/index.vue'
import ChartBtn from './chart-btn/index.vue'
import LogicChart from '@/components/apps/logics/chart/index.vue'

export default function headers(_this) {
  return [
    headerItem('Account', 'account', {
      cellClass: 'w-64',
      generator: (logic) => {
        const { name = '-', exchange = '-' } = logic.credential || {}
        return { value: `${name}<br/>(${exchange})` }
      },
    }),
    headerItem('Logic Name', 'name', {
      generator: (logic) => {
        return {
          value: logic.name,
        }
      },
    }),
    headerItem('Status', 'status', {
      generator: (logic) => {
        return {
          component: BadgeLogicStatus,
          props: { enable: logic.enable, hidden: !!logic.hidden },
        }
      },
    }),
    headerItem('ChartPL', 'chart', {
      generator: (logic) => {
        return (logic.profits || []).length ? {
          component: LogicChart,
          props: {
            logic,
          },
          events: {
            click: () => _this.showChart(logic),
          },
        } : {
          component: ChartBtn,
          events: {
            click: () => _this.showChart(logic),
          },
        }
      },
    }),
    headerItem('ChartError', 'error', {
      hidden: true,
      generator: (logic) => {
        return {
          component: ChartBtn,
          props: {
            color: 'error',
          },
          events: {
            click: () => _this.showErrorChart(logic),
          },
        }
      },
    }),
    headerItem('Action', '', {
      cellClass: 'font-bold',
      generator: (logic) => ({
        component: ItemActions,
        props: {
          logic: logic,
          clone: () => _this.clone(logic),
          detail: () => _this.showDetail(logic),
          toggleHidden: () => _this.toggleHidden(logic),
        },
      }),
    }),
  ].filter(el => !el.hidden)
}
