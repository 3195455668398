const TYPE_TEXT = 'text'
const TYPE_NUMBER = 'number'
const TYPE_SELECT_BOX = 'select'
const SIDE_OPTIONS = [
  { text: 'BUY & SELL', value: 3 },
  { text: 'BUY ONLY', value: 1 },
  { text: 'SELL ONLY', value: 2 },
]

const BUY_OR_SELL = [
  { text: 'Buy', value: 1 },
  { text: 'Sell', value: 2 },
]

/* eslint-disable  */
const DISPLAY_ORDERS = {
  '10-ai': _orders([
    'channels', 'exitMin',
    'tpRate', 'slRate',
    'side1', 'fiatVolume1',
    'side2', 'fiatVolume2',
    'side3', 'fiatVolume3',
    'side4', 'fiatVolume4',
    'side5', 'fiatVolume5',
  ]),
  '103-oklink-tether-banknote': _orders([
    'limit',
    'type',
    'side',
    'volume',
    'tpRate',
    'slRate',
  ]),
  '102-coinbase-spotflow': _orders([
    'type',
    'hantei',
    'flow',
    'volume',
    'side',
    'tpRate',
    'slRate',
  ]),
  '101-tether-circulating-supply': _orders([
    'diff',
    'volume',
    'tpRate',
    'slRate',
  ]),
  '100-bitcoin-etf-flow': _orders([
    'target',  'hantei',
    'mark', 'side',
    'volume',
    'tpRate', 'slRate',
  ]),
  '04-losscut-checker': _orders([
    'exchange', 'upperLimit',
    'hanteiSide', 'side',
    'hanteiVolume', 'volume',
    'tp', 'sl',
    'orderSpan', 'reEntrySpan',
  ]),
  '09-news': _orders([
    'keywords', 'volume',
    'tp', 'sl',
  ]),
  '08-toraripi': _orders([
    'fluctuation', 'upperLimit',
    'side', 'volume',
    'lowerLine', 'upperLine',
    'spread', 'tp',
    'orderSpan', 'reEntrySpan',
  ]),
  'logic-001': _orders([
    'upperLimit', 'tz',
    'side', 'volume',
    'flag1', 'flag2',
    'startHour1', 'startHour2',
    'endHour1', 'endHour2',
    'limit', 'stop',
    'orderSpan', 'reEntrySpan',
  ]),
  'logic-002': _orders([
    'judgement', 'volume',
    'limit', 'stop',
    'orderSpan', 'reEntrySpan',
    'upperLimit',
  ]),
  'logic-003': _orders([
    'judgement', 'volume',
    'limit', 'stop',
    'orderSpan', 'reEntrySpan',
    'upperLimit',
  ]),
  'logic-004': _orders([
    'upperLimit', 'rate1',
    'rate2', 'rate4',
    'rate3', 'rate5',
    'rate6', 'rate7',
    'orderSpan', 'reEntrySpan',
    'volume',
  ]),
  'logic-005': _orders([
    'entryRate1', 'entryRate2',
    'tpRate', 'slRate',
    'volume', 'upperLimit',
    'orderSpan', 'reEntrySpan',
  ]),
  'logic-006': _orders([
    'downRate', 'upRate',
    'time', 'slRate',
    'volume', 'upperLimit',
    'orderSpan', 'reEntrySpan',
  ]),
  'logic-007': _orders([
    'volume', 'upperLimit',
    'tpRate', 'slRate',
    'orderSpan', 'reEntrySpan',
  ]),
  'logic-008': _orders([
    'volume', 'entryRate',
    'slRate', 'trailingRate',
    'tpRate', 'slRate',
    'orderSpan', 'reEntrySpan',
    'upperLimit',
  ]),
  'logic-010': _orders([
    'account', 'ratio',
    'sideOption', 'maxVolume',
    'orderSpan', 'reEntrySpan',
    'upperLimit',
  ]),
  'logic-451': _orders([
    "usdVolume",
    'upbitCoin', 'sideOption',
    'entryRate','exitRate',
  ]),
  'logic-452': _orders([
    "usdVolume",
    'upbitCoin', 'sideOption',
    'entryRate', 'exitHour',
  ]),
  'logic-453': _orders([
    "usdVolume",
    'upbitCoin', 'sideOption',
    'entryRate', 'exitHour',
  ]),
  'logic-454': _orders([
    "usdVolume",
    'entryRate', 'sideOption',
  ]),
}
/* eslint-enable */

const BYBIT_PERIOD = [
  { text: '30M', value: '30' },
  { text: '1H', value: '60' },
  { text: '2H', value: '120' },
  { text: '6H', value: '360' },
  { text: '1D', value: 'D' },
]

const UP_DOWN = [
  { text: 'UP', value: 1 },
  { text: 'DOWN', value: 2 },
]

const HOURS = [...Array(24)].map((el, idx) => {
  return { text: `${idx}h`, value: idx }
})

const LXVX_TP_PERCENT = _number(
  'tpPercent',
  'TP (in percent)',
  'Lxvxのラインより([LxvxのHighの最小値] - [LxvxのLowの最大値]) * [TP]%の幅で利食い'
)

const FLUCTUATION = _number(
  'fluctuation',
  'Fluctuation (in percent) - 0 ~ 0.05'
)

const UPBIT_COIN_OPTIONS = [
  'ALL_COINS',
  'XRP',
  'ETC',
  // 'CVC',
  // 'DGB',
  // 'SC',
  // 'SNT',
  // 'WAVES',
  // 'NMR',
  // 'XEM',
  // 'QTUM',
  // 'BAT',
  // 'LSK',
  // 'STEEM',
  'DOGE',
  // 'BNT',
  // 'XLM',
  // 'ARDR',
  // 'ARK',
  // 'STORJ',
  // 'GRS',
  // 'RLC',
  // 'NEO',
  // 'MTL',
  'ADA',
  // 'MANA',
  // 'SBD',
  // 'POWR',
  // 'BTG',
  // 'DNT',
  // 'ZRX',
  // 'TRX',
  // 'TUSD',
  // 'LRC',
  // 'ICX',
  // 'EOS',
  // 'POLYX',
  // 'ONT',
  // 'ZIL',
  // 'LOOM',
  'BCH',
  // 'HIFI',
  // 'IOST',
  // 'RFR',
  // 'IQ',
  'IOTA',
  // 'RVN',
  // 'GO',
  // 'UPP',
  // 'ENJ',
  // 'ONG',
  // 'GAS',
  // 'ELF',
  // 'KNC',
  // 'MOC',
  // 'BSV',
  // 'THETA',
  // 'DENT',
  // 'QKC',
  // 'BTT',
  // 'IOTX',
  // 'SOLVE',
  // 'NKN',
  // 'META',
  // 'ANKR',
  // 'CRO',
  // 'TFUEL',
  // 'ORBS',
  // 'AERGO',
  'ATOM',
  // 'TT',
  // 'CRE',
  // 'STPT',
  // 'MBL',
  // 'DAI',
  // 'MKR',
  // 'BORA',
  // 'WAXP',
  // 'HBAR',
  // 'MED',
  // 'MLK',
  // 'VET',
  // 'CHZ',
  // 'FX',
  // 'OGN',
  // 'XTZ',
  // 'HIVE',
  // 'HBD',
  // 'OBSR',
  // 'DKA',
  // 'STMX',
  // 'AHT',
  // 'LINK',
  // 'KAVA',
  // 'JST',
  // 'CHR',
  // 'DAD',
  // 'TON',
  // 'CTSI',
  'DOT',
  // 'COMP',
  // 'SXP',
  // 'HUNT',
  // 'ONIT',
  // 'CRV',
  // 'ALGO',
  // 'RSR',
  // 'OXT',
  // 'PLA',
  // 'MARO',
  'SAND',
  // 'SUN',
  // 'QTCON',
  // 'MVL',
  // 'REI',
  // 'AQT',
  // 'AXS',
  // 'STRAX',
  // 'GLM',
  // 'FCT2',
  // 'SSX',
  // 'FIL',
  'UNI',
  // 'INJ',
  // 'PROM',
  // 'VAL',
  // 'PSG',
  // 'JUV',
  // 'CBK',
  // 'FOR',
  // 'BFC',
  // 'LINA',
  // 'HPO',
  'CELO',
  // 'STX',
  // 'NEAR',
  // 'AUCTION',
  // 'DAWN',
  // 'FLOW',
  // 'STRK',
  // 'PUNDIX',
  // 'GRT',
  // 'SNX',
  // 'USDP',
  // 'XEC',
  'SOL',
  'MATIC',
  'AAVE',
  // '1INCH',
  // 'MASK',
  // 'AUDIO',
  // 'YGG',
  // 'GTC',
  // 'OCEAN',
  // 'CTC',
  // 'LPT',
  // 'AVAX',
  // 'IMX',
  // 'RNDR',
  // 'RLY',
  // 'T',
  // 'RAD',
  // 'AGLD',
  // 'API3',
  'ARPA',
  // 'ENS',
  'GMT',
  // 'APE',
  // 'RAY',
  // 'APT',
  // 'ACM',
  // 'AFC',
  // 'ATM',
  // 'BAR',
  // 'CITY',
  // 'INTER',
  // 'NAP',
  'SHIB',
  // 'GAL',
  // 'ASTR',
  // 'BLUR',
  // 'ACS',
  // 'MAGIC',
  // 'ARB',
  // 'EGLD',
  // 'SUI',
  'MINA',
  // 'STG',
  // 'SEI',
].map((coin) => ({ text: coin, value: coin }))

const FIELDS = Object.fromEntries(
  [
    _number('fiatVolume', 'Entry lot (in USD)'),
    _number('usdVolume', 'Entry lot (in USD)'),
    _number('entryRate', 'HANTEI Rate entry (in percent)'),
    _select('hantei', 'HANTEI condition (entry)', UP_DOWN),
    _number('exitRate', 'HANTEI Rate exit (in percent)'),
    _number('exitHour', 'Exit timing after entry (in hour)'),
    _select('upbitCoin', 'Coin', UPBIT_COIN_OPTIONS),
    _select('sideOption', 'Side', SIDE_OPTIONS),
    _number(
      'delayEntry',
      'Delay entry (in seconds)',
      'entry条件を満たしてから執行までの秒数'
    ),
    _number(
      'delayExit',
      'Delay exit (in seconds)',
      'exit条件を満たしてから執行までの秒数'
    ),
    _number('upperLimit', 'Position Limit', '最大保有ポジション数'),
    _number(
      'limit',
      'Limit (in percent)',
      'エントリー方向に[Limit]%動いた場合に成行注文'
    ),
    _number(
      'stop',
      'Stop (in percent)',
      'エントリーと逆方向に[Stop]%動いた場合に成行注文'
    ),
    _select(
      'tz',
      'Time Zone',
      Array(12)
        .fill(null)
        .map((el, idx) => ({
          value: idx,
          text: `GMT +${idx}`,
        }))
    ),
    _number('volume', 'Entry lot (in USD)'),
    _select('side', 'Entry Side', [
      { text: 'Buy', value: 1 },
      { text: 'Sell', value: 2 },
    ]),
    _number('tpRate', 'TP Rate (in percent)'),
    _number('slRate', 'SL Rate (in percent)'),
    _number('tp', 'TP (in USD)', 'エントリーと方向に[TP]動いた場合に成行注文'),
    _number(
      'sl',
      'SL (in USD)',
      'エントリーと逆方向に[SL]動いた場合に成行注文'
    ),
    _number(
      'trailing',
      'Trailing (in percent)',
      '[Trailing]%のトレール注文を実行。'
    ),
    _number(
      'orderSpan',
      'Entry span (in minutes)',
      'エントリーしてから次のエントリーの最低間隔'
    ),
    _number('judgement', 'Judgment (in percent)'),
    _number(
      'reEntrySpan',
      'Re Entry span (in minutes)',
      '決済してから次のエントリーの最低間隔'
    ),
    _number(
      'exitMin',
      'Exit (in minute)',
      'エントリーから[ Exit minute ]経過でエグジット'
    ),
    _number('bars', 'Indicator period'),
    _select('resolution', 'Indicator resolution', [
      { text: '1M', value: 1 },
      { text: '5M', value: 5 },
      { text: '15M', value: 15 },
      { text: '30M', value: 30 },
      { text: '1H', value: 60 },
      { text: '2H', value: 120 },
      { text: '4H', value: 240 },
      { text: '1D', value: 360 },
    ]),
  ].map((el) => [el.key, el])
)

const CUSTOM_FIELDS = {
  '100-bitcoin-etf-flow': [
    _select(
      'target',
      'Target',
      [
        'TOTAL',
        'IBIT',
        'FBTC',
        'BITB',
        'ARKB',
        'BTCO',
        'EZBC',
        'BRRR',
        'HODL',
        'BTCW',
        'GBTC',
      ].map((value) => ({ text: value, value }))
    ),
    _number(
      'mark',
      'Mark value (in mil USD)',
      'BITCOIN ETF FLOW の[Target]が[Mark]mil USD 以上なら指定Lotでロングエントリー'
    ),
  ],
  '101-tether-circulating-supply': [
    _number('diff', '前日比より発行量の増加分（mil.$）'),
  ],
  '103-oklink-tether-banknote': [
    _number('limit', 'USDT BURN / MINT value (in USDT)'),
    _select('type', 'Type', [
      { text: 'MINT', value: 1 },
      { text: 'BURN', value: 2 },
    ]),
  ],
  '102-coinbase-spotflow': [
    _number('flow', 'Coinbase Flow value (in BTC)'),
    _select('type', 'Type', [
      { text: 'IN_FLOW', value: 1 },
      { text: 'OUT_FLOW', value: 2 },
      { text: 'IN_MINUS_OUT', value: 3 },
      { text: 'OUT_MINUS_IN', value: 4 },
    ]),
  ],
  '01-td9': [_select('period', 'Period', BYBIT_PERIOD)],
  '03-deribit-funding-rate': [
    _number('percent', 'HANTEI Funding rate (in percent)'),
  ],
  '04-losscut-checker': [
    _select('exchange', 'Exchange', [
      { text: 'BITMEX', value: 'bitmex' },
      { text: 'BYBIT', value: 'bybit' },
      { text: 'BITFINEX', value: 'bitfinex' },
      { text: 'OKEX', value: 'okex' },
      { text: 'ALL EXCHANGES', value: 'all' },
    ]),
    _select('hanteiSide', 'HANTEI Losscut side (entry)', [
      { text: 'BUY', value: 1 },
      { text: 'SELL', value: 2 },
    ]),
    _number('hanteiVolume', 'HANTEI Losscut volume'),
  ],
  '05-simpson': [
    _select('interval', 'Period', BYBIT_PERIOD.slice(0, 4)),
    _number('entryPercent', 'Entry Percent'),
    _number('rangePercent', 'Range Percent'),
  ],
  '06-funding-rate-entry': [
    _select('exchange', 'Exchange', [
      { text: 'BINANCE', value: 'binance' },
      { text: 'BYBIT', value: 'bybit' },
      { text: 'DERIBIT', value: 'deribit' },
    ]),
  ],
  '111-doten-kun': [
    _select('interval', 'Period', [
      { text: '30M', value: '30' },
      { text: '1H', value: '60' },
      { text: '2H', value: '120' },
      { text: '4H', value: '240' },
      { text: '6H', value: '360' },
      { text: '12H', value: '720' },
      { text: '1D', value: 'D' },
    ]),
    _number('upperLimit', 'Position Limit (fixed)', '最大保有ポジション数', {
      hidden: true,
      disabled: true,
    }),
    _number(
      'orderSpan',
      'Entry span (in minutes)',
      'エントリーしてから次のエントリーの最低間隔',
      { hidden: true, disabled: true }
    ),
    _number(
      'reEntrySpan',
      'Re Entry span (in minutes)',
      '決済してから次のエントリーの最低間隔',
      { hidden: true, disabled: true }
    ),
  ],
  'logic-001': [
    _select('flag1', 'Entry flag 1', UP_DOWN),
    _select('flag2', 'Entry flag 2', UP_DOWN),
    _select('startHour1', 'Judgement-1 start', HOURS),
    _select('endHour1', 'Judgement-1 end', HOURS),
    _select('startHour2', 'Judgement-2 start', HOURS),
    _select('endHour2', 'Judgement-2 end', HOURS),
  ],
  'logic-002': [
    _number(
      'judgement',
      'Judgment (in percent)',
      '[Judgment]%以上ならNasdaq1時間で動いた方向にエントリー'
    ),
  ],
  'logic-004': [
    _number('rate1', 'Entry Rate 1 (init) (in percent)'),
    _number('rate2', 'Entry Rate 2 (a) (in percent)'),
    _number('rate3', 'SL rate 1 (b) (in percent)'),
    _number('rate4', 'Entry Rate 3 (b) (in percent)'),
    _number('rate5', 'SL rate 2 (c) (in percent)'),
    _number('rate6', 'TP Rate (c) (in percent)'),
    _number('rate7', 'SL Rate - NYDOW (c) (in percent)'),
  ],
  'logic-005': [
    _number('entryRate1', 'UP Rate for 8h (in percent)'),
    _number('entryRate2', 'UP Rate for 20m (in percent)'),
    _number('tpRate', 'TP UP rate (in percent)'),
    _number('slRate', 'SL DOWN Rate (in percent)'),
  ],
  'logic-006': [
    _number('downRate', 'DOWN Rate for 8h (in percent)'),
    _number('time', 'HANTEI duration (in minute)'),
    _number('upRate', 'UP rate after entry (in percent)'),
    _number('slRate', 'SL DOWN Rate (in percent)'),
    _number('upperLimit', 'Position Limit (fixed)', null, { disabled: true }),
    _number(
      'orderSpan',
      'Entry span (in minutes)',
      'エントリーしてから次のエントリーの最低間隔',
      { disabled: true }
    ),
  ],
  'logic-008': [
    _number('entryRate', 'HANTEI Rate for 8h (in percent)'),
    _number('slRate', 'SL Rate (in percent)'),
    _number('trailingRate', 'Trailing Rate (in percent)'),
  ],
  'logic-010': [
    _text('account', 'Target address'),
    _number('maxVolume', 'Maximum total position volume'),
    _number('ratio', 'Copy ratio (in percent)'),
    _select('sideOption', 'Side', SIDE_OPTIONS),
  ],
  'logic-011': [_text('excludeCoins', 'Excluded Coins')],
  '02-weekly-opening-window': [
    _number('entrySpreadPercent', 'Entry spread (in percent)'),
  ],
  '08-toraripi': [
    FLUCTUATION,
    _number('lowerLine', 'Lower LINE'),
    _number('upperLine', 'Upper LINE'),
    _number('spread', 'Entry spread'),
    _number('tp', 'Profit spread'),
  ],
  '09-news': [
    _text(
      'keywords',
      'Entry keywords',
      '判定用のキーワード。半角のコンマ（,）切り'
    ),
    _number('sl', 'Stoploss spread'),
    _number('tp', 'Profit spread'),
  ],
  '17-stable-coin': [
    _number('value1', 'Limit 1'),
    _number('value2', 'Limit 2'),
    _number('value3', 'Limit 3'),
    _number('volume1', 'Volume 1'),
    _number('volume2', 'Volume 2'),
    _number('volume3', 'Volume 3'),
    _select(
      'coin',
      'Stable coin',
      ['usdt', 'usdc'].map((coin) => ({
        text: coin.toUpperCase(),
        value: coin,
      }))
    ),
  ],
  '60-envelop': [
    _select('entryLine', 'Envelop Line (entry)', [
      { text: 'UP-1', value: 0 },
      { text: 'UP-2', value: 1 },
      { text: 'SMA', value: 2 },
      { text: 'LOW-2', value: 3 },
      { text: 'LOW-1', value: 4 },
    ]),
    _select('exitLine', 'Envelop Line (exit)', [
      { text: 'UP-1', value: 0 },
      { text: 'UP-2', value: 1 },
      { text: 'SMA', value: 2 },
      { text: 'LOW-2', value: 3 },
      { text: 'LOW-1', value: 4 },
    ]),
    _number('up1', 'Upper deviation rate - 1 (in percent)'),
    _number('up2', 'Upper deviation rate - 2 (in percent)'),
    _number('low2', 'Lower deviation rate - 2 (in percent)'),
    _number('low1', 'Lower deviation rate - 1 (in percent)'),
    _select('entryHantei', 'HANTEI condition (entry)', UP_DOWN),
    _select('exitHantei', 'HANTEI condition (exit)', UP_DOWN),
  ],
  '64-hoteto': [
    _number(
      'entryPeriod',
      'Hantei (in minutes) - for Entry',
      '直近[Hantei](minute)の高値から[Entry]%の下落でエントリー。'
    ),
    _number(
      'entry',
      'Entry (in percent)',
      '直近[Hantei](minute)の高値から[Entry]%の下落でエントリー。'
    ),
    _number(
      'close',
      'Exit (in percent)',
      '直近[Hantei](minute)の安値から[Exit]%の上昇で決済。'
    ),
    _number(
      'tp',
      'TP (in USD)',
      'エントリーと方向に[TP]動いた場合に成行注文。'
    ),
  ],
  '65-iv-band-signal': [
    _select('ivLine', 'Entry Hantei Line', [
      { text: 'BITVOL UP', value: 1 },
      { text: 'LXVX UP', value: 2 },
      { text: 'LXVX DOWN', value: 3 },
      { text: 'BITVOL DOWN', value: 4 },
    ]),
    _number(
      'exitProfit',
      'Exit Profit (in percent)',
      '[Entry Hantei Line] 内側上下の値幅に対して[Exit_Profit]%動いた時に成行決済。'
    ),
    _select('hantei', 'Entry Hantei IV Line', UP_DOWN),
    _number(
      'exitLoss',
      'Exit Loss (in percent)',
      '[Entry Hantei Line] 内側上下の値幅に対して[Exit_Loss]%動いた時に成行決済。'
    ),
  ],
  '10-ai': [
    _text('channels', 'Twitter channels', 'Split by comma'),
    _select('side1', 'Entry Side (1)', BUY_OR_SELL),
    _number('fiatVolume1', 'Entry lot (1) (in USD)'),
    _select('side2', 'Entry Side (2)', BUY_OR_SELL),
    _number('fiatVolume2', 'Entry lot (2) (in USD)'),
    _select('side3', 'Entry Side (3)', BUY_OR_SELL),
    _number('fiatVolume3', 'Entry lot (3) (in USD)'),
    _select('side4', 'Entry Side (4)', BUY_OR_SELL),
    _number('fiatVolume4', 'Entry lot (4) (in USD)'),
    _select('side5', 'Entry Side (5)', BUY_OR_SELL),
    _number('fiatVolume5', 'Entry lot (5) (in USD)'),
  ],
  '66-tweet': [
    _text(
      'channel',
      'Twitter channel',
      'https://twitter.com/elonmusk → elonmusk'
    ),
    _text(
      'keywords',
      'Entry keywords',
      '判定用のキーワード。半角のコンマ（,）切り'
    ),
    _number(
      'waitMin',
      'Wait minute',
      '[Entry wait minute]待って、Volumeがその前の1分間の[Volume multi] 倍以上であればエントリー判定'
    ),
    _number(
      'multi',
      'Volume multi',
      '[Entry wait minute]待って、Volumeがその前の1分間の[Volume multi] 倍以上であればエントリー判定'
    ),
  ],
  '67-funding-rate-logic': [
    _number(
      'buyRate',
      'BUY Hantei Rate (in percent)',
      '「[BUY Hantei Rate] > Pre FR」でエントリ判定'
    ),
    _number(
      'sellRate',
      'SELL Hantei Rate (in percent)',
      '「[SELL Hantei Rate] < Pre FR」でエントリ判定'
    ),
  ],
  '68-funding-catch': [
    _number(
      'buyRate',
      'BUY Hantei Rate (in percent)',
      '「受渡確定時の2時間前のFunding Rate < [BUY Hantei Funding Rate]」でエントリ判定'
    ),
    _number(
      'sellRate',
      'SELL Hantei Rate (in percent)',
      '「受渡確定時の2時間前のFunding Rate > [SELL Hantei Funding Rate]」でエントリ判定'
    ),
    _number(
      'range',
      'Hantei Range (in percent)',
      '過去3時間の値幅が[Hantei Range]以下でエントリ判定'
    ),
    _number(
      'buyPercent',
      'BUY rate (in percent)',
      '「(現在値 - 過去3時間の安値) / (過去3時間の高値-安値)」 が[BUY rate]以下でエントリ判定'
    ),
    _number(
      'sellPercent',
      'SELL rate (in percent)',
      '「(現在値 - 過去3時間の安値) / (過去3時間の高値-安値)」 が[SELL rate]以上でエントリ判定'
    ),
  ],
  '69-funding-down': [
    _number(
      'buyRate',
      'BUY Hantei FR (in percent)',
      '「確定時のFunding Rate < [BUY Hantei Funding Rate]」でエントリ判定'
    ),
    _number(
      'buyPreRateChange',
      'BUY FR Change (in percent)',
      '「Berfor PreFR > After PreFR + [BUY Funding Rate Change]」でエントリ判定'
    ),
    _number(
      'sellRate',
      'SELL Hantei FR (in percent)',
      '「確定時のFunding Rate > [SELL Hantei Funding Rate]」でエントリ判定'
    ),
    _number(
      'sellPreRateChange',
      'SELL FR Change (in percent)',
      '「Berfor PreFR < After PreFR + [SELL Funding Rate Change]」でエントリ判定'
    ),
    _number(
      'priceChange',
      'Price change (in percent)',
      '「確定前2時間価格 -  確定時価格  > 確定時価格*[ Price change Rate ]」でエントリ判定'
    ),
  ],
  '71-iv-band-signal-2': [LXVX_TP_PERCENT],
  '72-iv-band-signal-II': [LXVX_TP_PERCENT],
  '73-iv-band-signal-III': [LXVX_TP_PERCENT],
  '112-iv-band-signal': [LXVX_TP_PERCENT, FLUCTUATION],
  '74-two-step-logic': [
    _number('minute', 'HANTEI minute'),
    _number('vol1', 'Volume 1'),
    _number('vol2', 'Volume 2'),
    _number('LC', 'Loss cut (in percent)'),
    _number('TP', 'Take Profit (in percent)'),
    _select('reverse', '順張り・逆張り', [
      { text: '逆張り', value: 0 },
      { text: '順張り', value: 1 },
    ]),
  ],
  '75-oi-logic': [
    _number(
      'minute',
      'HANTEI minute',
      'OIが直近[Hantei Minute]分前のOIより[OI Decrease]以上減少でエントリー判定'
    ),
    _number(
      'priceDecrease',
      'Price Decline',
      '直近[Hantei Minute]分前から[Price Decline]以上下落でエントリー判定'
    ),
    _number('oiDecrease', 'OI Decrease'),
    _number(
      'range',
      'Stop Price Range',
      'エントリー価格から[Stop_price_range]以上逆行したら損切り'
    ),
  ],
}

module.exports = {
  DISPLAY_ORDERS,
  FIELDS,
  CUSTOM_FIELDS,
  TYPE_TEXT,
  TYPE_NUMBER,
  TYPE_SELECT_BOX,
}

function _number(key, label, description = '', { disabled, hidden } = {}) {
  return { key, label, type: TYPE_NUMBER, description, disabled, hidden }
}

function _text(key, label, description = '') {
  return { key, label, type: TYPE_TEXT, description }
}

function _select(key, label, items, description = '') {
  return { key, label, type: TYPE_SELECT_BOX, items, description }
}

function _unique(arr) {
  return [...new Set(arr)]
}

function _orders(keys) {
  return Object.fromEntries(
    _unique([
      ...keys,
      'delayEntry',
      'delayExit',
      'orderSpan',
      'reEntrySpan',
      'sideOption',
      'upperLimit',
    ]).map((key, index) => [key, index + 1])
  )
}
