<template>
  <div class="max-w-none w-full md:w-4/6">
    <div class="w-full flex justify-center flex-col">
      <div class="font-semibold">
        <div class="flex">
          <div>Name:&nbsp;</div>
          <p class="text-gray-600">{{ logic.name }}</p>
        </div>
        <div class="flex">
          <div>Exchange:&nbsp;</div>
          <p class="text-gray-600">
            {{ logic.credential?.exchange?.toUpperCase() }}
          </p>
        </div>
        <div class="flex">
          <div>Account:&nbsp;</div>
          <p class="text-gray-600">
            {{ logic.credential?.name }}
          </p>
        </div>
      </div>

      <chart :options="option" constructor-type="stockChart" class="mx-auto" />

      <div class="flex mt-4 w-full justify-end"></div>
    </div>
  </div>
</template>

<script>
import cloneDeep from 'lodash.clonedeep'
import { Chart } from 'highcharts-vue'
import _option from './_options.js'

export default {
  name: 'ErrorChart',

  components: {
    Chart,
  },

  props: {
    logic: {
      type: Object,
      required: true,
    },
  },

  data: () => ({
    option: {},
  }),

  created() {
    this.option = cloneDeep(_option)
  },

  mounted() {
    this.fetchOrders()
  },

  methods: {
    async fetchOrders() {
      const orders = await this.$http.get(
        `/api/v2/Order?isPaging=false&limit=10000&filter=${JSON.stringify({
          magicNumber: this.logic.magicNumber,
          // status: 2,
        })}`
      )
      const entry = orders
        .map((el) => {
          el.entry.volume = el.volume
          return el.entry
        })
        .filter((el) => el.price < 1)
      const close = orders
        .filter((el) => el.close && el.close.price < 1)
        .map((el) => {
          el.close.volume = el.volume
          return el.close
        })

      const records = entry.concat(close).map((el) => {
        el.ts = new Date(el.timestamp).getTime()
        return el
      })
      records.sort((a, b) => a.ts - b.ts)
      global.debug(records)
      records.forEach((el) => {
        const k = el.side === 1 ? 1 : -1
        this.option.series[0].data.push([el.ts, k * el.volume])
      })
    },
  },
}
</script>
