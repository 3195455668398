import { headerItem } from '@/utils/table'
import DInput from '@/components/daisy/atoms/input/index.vue'
import DSelect from '@/components/daisy/atoms/select/index.vue'
import FlexibleInput from '@/components/daisy/molecules/flexible-input/index.vue'

function _item(label, key, _this) {
  return headerItem(label, key, {
    generator: (item) => {
      return {
        component: DInput,
        props: {
          value: item[key] || '',
        },
        events: {
          onChange: (value) => {
            _this.setValue(item.index, key, value)
          },
        },
      }
    },
  })
}

export default function headers(_this) {
  return [
    headerItem('Status', 'status', {
      generator: (item) => {
        return {
          component: DInput,
          props: {
            type: 'checkbox',
            checked: item.enable,
            class: 'toggle border-solid toggle-primary h-6',
            value: item.enable,
          },
          events: {
            input: () => {
              _this.setValue(item.index, 'enable', !item.enable)
            },
          },
        }
      },
    }),
    _item('Logic Name', 'name', _this),
    headerItem('Account', 'credential', {
      class: 'min-w-32',
      generator: (item) => {
        return {
          component: DSelect,
          props: {
            disabled: !item?.storages?.init,
            value: item.credential._id || item.credential,
            items: _this.accountOptions,
          },
          events: {
            input: (value, accountOption) => {
              _this.setValue(item.index, 'credential', {
                _id: accountOption.value,
                name: accountOption.label,
              })
            },
          },
        }
      },
    }),
    ..._this.configs().map(({ key, field }) => {
      return headerItem(field.label, key, {
        skipI18n: true,
        class: field.type === 'select' ? 'min-w-32' : '',
        generator: (item) => {
          return {
            component: FlexibleInput,
            props: {
              inputType: field.type,
              disabled: field.disabled,
              items: field.items,
              value: item.config[key] || '',
            },
            events: {
              input: (value) => {
                if (field.type === 'number') {
                  _this.setConfigValue(item.index, key, parseFloat(value))
                  return
                }
                _this.setConfigValue(item.index, key, value)
              },
            },
          }
        },
      })
    }),
    _item('Memo', 'memo', _this),
  ]
}
