<template>
  <div class="w-full">
    <div class="items-center flex w-full">
      <d-btn
        v-if="showFilter"
        :class="selectedAccountId ? 'invisible' : ''"
        size="xs"
        :color="allLogics ? 'primary' : 'secondary'"
        @click="showallLogics"
      >
        <d-icon :name="allLogics ? 'eye-off' : 'eye'" size="sm" />
        <span class="ml-1">
          {{ allLogics ? 'Hide HIDDEN logics' : 'Show all logics' }}
        </span>
      </d-btn>
      <div
        v-show="selectedLogicIds.length > 1"
        class="flex flex-row items-center"
      >
        <d-btn
          @click="item.handler"
          :color="item.color"
          class="mr-2"
          v-for="(item, key) of buttons"
          :key="key"
          size="xs"
        >
          <d-icon :name="item.icon" size="sm" />
          <span class="ml-1">{{ item.text }}</span>
        </d-btn>
      </div>
      <div class="ml-auto">
        <d-btn
          v-if="selectedAccountId"
          size="xs"
          color="warning"
          @click.stop="_clear"
        >
          {{ $t('Clear') }}
        </d-btn>
        <div class="flex-center space-x-2">
          <template v-if="showFilter">
            <account-filter
              :key="key"
              @onChange="(selected) => (accountIds = selected)"
            />
            <logic-filter
              :key="key + 1"
              @onChange="(selected) => (logicIds = selected)"
            />
            <button
              class="ml-auto btn-xs-warning"
              v-if="logicIds.length || accountIds.length"
              @click.stop="
                () => {
                  logicIds = []
                  accountIds = []
                  key = key + 2
                }
              "
            >
              {{ $t('Reset') }}
            </button>
          </template>
        </div>
      </div>
    </div>

    <div class="mt-2">
      <div class="overflow-x-scroll">
        <d-table
          :key="counter"
          class="my-4"
          :headers="headers"
          :items="_items"
          compact
          tickAble
          @onChecked="onChecked"
          wrapperClasses="min-h-screen overflow-y-scroll"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { buildQuery } from '@/utils/string/index.js'
import { ONE_MINUTE } from '@/common/constants.js'
import headerGenerator from './headers.js'
import CloneModal from '@/components/apps/logics/clone-logic/index.vue'
import LogicModal from '@/components/apps/logics/logic-modal/index.vue'
import BatchEditModal from '@/components/apps/logics/batch-edit-modal/index.vue'
import LogicChartModal from '@/components/apps/logics/logic-chart-modal/index.vue'
import ErrorChartModal from '@/components/apps/logics/error-chart-modal/index.vue'

export default {
  name: 'my-bots',

  meta: {
    title: 'My Bots',
  },

  props: {
    showFilter: {
      type: Boolean,
      default: true,
    },
    _accountIds: {
      type: Array,
      default: () => [],
    },
    _selectedIds: {
      type: Array,
      default: () => [],
    },
  },

  data: () => ({
    counter: 1,
    key: 1,
    logics: [],
    logicById: {},
    accountIds: [],
    logicIds: [],
    selectedLogicIds: [],
    filterLogics: [],
    modalComponent: null,
    logic: null,
    allLogics: false,
  }),

  created() {
    if (!this.showFilter) {
      this.accountIds = this._accountIds
      this.selectedIds = this._selectedIds
    }
    this._fetch()
  },

  computed: {
    ...mapGetters({
      accountMap: 'credentials/accountMap',
    }),
    selectedAccountId() {
      return this.$route.query?.accountId || null
    },
    headers() {
      return headerGenerator(this)
    },
    _items() {
      let logics = this.logics

      if (!this.allLogics) {
        logics = logics.filter((item) => !item.hidden)
      }

      if (this.logicIds.length) {
        logics = logics.filter((el) => {
          return this.logicIds.includes(el._id)
        })
      }

      if (this.accountIds.length) {
        logics = logics.filter((el) => {
          return this.accountIds.includes(el.credential?._id || '-')
        })
      }

      if (this.selectedAccountId) {
        logics = logics.filter((el) => {
          return this.selectedAccountId === el.credential?._id
        })
      }

      return logics
    },
    buttons() {
      return [
        {
          text: this.$t('actions.on'),
          icon: 'power',
          color: 'primary',
          handler: this.switchOn,
        },
        {
          text: this.$t('actions.off'),
          icon: 'bolt-off',
          color: 'warning',
          handler: this.switchOff,
        },
        {
          text: this.$t('actions.batchEdit'),
          icon: 'settings',
          color: 'error',
          handler: this.batchEdit,
          hidden: !this.isDisplayBatchEditButton,
        },
        {
          text: this.$t('Clear'),
          icon: 'clear-all',
          color: 'warning',
          handler: () => {
            this.selectedLogicIds = []
            this.counter++
          },
        },
      ].filter((el) => !el.hidden)
    },
    isDisplayBatchEditButton() {
      if (this.selectedLogicIds.length < 1) {
        return false
      }
      const { code } = this.logicById[this.selectedLogicIds[0]]
      const id = this.selectedLogicIds.find((id) => {
        return this.logicById[id]?.code !== code
      })
      return !id
    },
  },

  methods: {
    _reset() {
      this.logicIds = []
      this.accountIds = []
      this.key = Date.now()
    },
    showallLogics() {
      this.allLogics = !this.allLogics
    },
    async _fetch() {
      const url = `/api/logics?${buildQuery({
        filters: { public: false },
        sort: { _id: -1 },
        all: true,
      })}`
      const res = await this.$http.get(url)
      this.logics = (res?.data || [])
        .map((logic) => {
          if (logic.code === 'manual-order') {
            return null
          }
          logic.credential = this.accountMap[logic.credential]
          if (!logic.credential) {
            return null
          }
          ;['reEntrySpan', 'orderSpan'].map((key) => {
            if (logic.config?.[key]) {
              logic.config[key] = parseInt(logic.config[key] / ONE_MINUTE)
            }
          })
          return logic
        })
        .filter(Boolean)

      this.logicById = this.logics.reduce((logics, logic) => {
        logics[logic._id] = logic
        return logics
      }, {})
    },
    onChecked(items) {
      this.selectedLogicIds = items
    },
    showErrorChart(item) {
      this.$$showModal({
        component: ErrorChartModal,
        props: {
          logic: item,
        },
      })
    },
    _clear() {
      this.selectedAccountId = null
      this.$router.push({ name: 'my-bots' })
    },
    showChart(item) {
      this.$$showModal({
        component: LogicChartModal,
        props: {
          logic: item,
        },
      })
    },
    async swich(on) {
      try {
        const magicNumbers = this.selectedLogicIds.map((id) => {
          return this.logicById[id].magicNumber
        })
        await this.$$confirm({
          title: this.$t(on ? 'turnOnLogic' : 'turnOffLogic'),
          text: this.$t(on ? 'turnOnText' : 'turnOffText'),
          confirmText: this.$t(on ? 'turnOnConfirmText' : 'turnOffConfirmText'),
          confirmColor: on ? 'primary' : 'warning',
          onConfirm: async () => {
            await this.$http.post('/api/logics/switch', {
              magicNumbers,
              enable: on,
            })
            this.$toast.success(this.$t('updateSuccess'))
            await this._fetch()
          },
        })
      } catch (err) {
        this.$toast.success(this.$t('updateFailed'))
        global.log(err)
      }
    },
    switchOn() {
      this.swich(true)
    },
    switchOff() {
      this.swich(false)
    },
    async toggleHidden(logic) {
      const url = `/api/v2/Logic/${logic._id}`
      await this.$http.put(url, { hidden: !logic.hidden })
      this._fetch()
      this.counter++
    },
    clone(logic) {
      const props = { logic }
      const events = {
        onClose: () => this._fetch(),
      }
      this.$$showModal({
        component: CloneModal,
        props,
        events,
      })
    },
    batchEdit() {
      const logics = this.selectedLogicIds.map((_id) => this.logicById[_id])
      this.$$showModal({
        component: BatchEditModal,
        props: { logics },
        events: {
          onClose: () => this._fetch(),
        },
      })
    },
    showDetail(logic) {
      this.$$showModal({
        component: LogicModal,
        props: { logic },
        events: {
          onClose: () => this._fetch(),
        },
      })
    },
  },
}
</script>
