<template>
  <div class="badge font-bold capitalize" :class="classes">
    {{ text }}
  </div>
</template>

<script>
export default {
  name: 'badge-logic-status',

  props: {
    enable: {
      type: Boolean,
    },
    hidden: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    classes() {
      return this.enable ? 'badge-primary' : 'badge-error'
    },
    text() {
      return this.hidden ? 'HIDDEN' : this.enable ? 'ON' : 'OFF'
    },
  },
}
</script>
