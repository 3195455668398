<template>
  <div class="cursor-pointer" v-if="lines.length" @click.stop="click">
    <apexchart
      :width="width || 120"
      :height="height || 120"
      type="line"
      :options="options"
      :series="series"
    />
  </div>
</template>

<script>
import cloneDeep from 'lodash.clonedeep'

const chartOptions = {
  chart: {
    toolbar: {
      show: false,
    },
  },
  colors: ['#166913'],
  grid: {
    show: false,
  },
  stroke: {
    curve: 'smooth',
    width: 1,
  },
  tooltip: {
    enabled: false,
  },
  xaxis: {
    axisTicks: {
      show: false,
    },
    labels: {
      show: false,
    },
    axisBorder: {
      show: false,
    },
  },
  yaxis: {
    labels: {
      show: false,
    },
  },
  dataLabels: {
    enabled: false,
  },
  legend: {
    show: false,
  },
}

export default {
  name: 'logic-chart',

  props: {
    logic: Object,
    width: Number,
    height: Number,
  },

  computed: {
    lines() {
      return this.logic?.profits || []
    },
    options() {
      const options = cloneDeep(chartOptions)
      options.chart.id = `${this.logic?._id}`
      if (this.label) {
        options.title = {
          text: this.label,
          align: 'center',
          style: {
            fontSize: 11,
          },
        }
      }
      return options
    },
    series() {
      return [{ data: this.lines }]
    },
  },

  methods: {
    click($event) {
      this.$emit('click', $event)
    },
  },
}
</script>
