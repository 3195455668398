<template>
  <div class="max-w-full w-11/12 h-full flex flex-col space-y-4">
    <div class="flex items-center justify-between">
      <span class="font-medium text-lg">
        {{ $t('Batch update') }}
      </span>
      <div class="inline-flex space-x-2 m-2">
        <div class="flex items-center justify-between space-x-2">
          <span class="font-medium">
            {{ $t('Batch edit') }}
          </span>
          <input
            v-model="isBatchEdit"
            type="checkbox"
            checked="checked"
            class="toggle border-solid toggle-primary"
          />
        </div>
      </div>
    </div>
    <div class="flex-grow overflow-scroll border">
      <d-table :headers="headers" :items="list" />
    </div>
    <div class="flex items-center justify-center space-x-4">
      <button class="btn-sm-primary" @click.stop="save">
        {{ $t('Save') }}
      </button>
      <button class="btn-sm-warning" @click="$$closeModal">
        {{ $t('Cancel') }}
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  FIELDS,
  CUSTOM_FIELDS,
  DISPLAY_ORDERS,
} from '@/common/logic-configs.js'

import clonedeep from 'lodash.clonedeep'
import headersGenerator from './headers.js'
import { ONE_MINUTE } from '@/common/constants.js'

export default {
  name: 'batch-edit-modal',

  props: {
    logics: {
      type: Array,
      default: () => [],
    },
  },

  data: () => ({
    isBatchEdit: false,
    list: [],
  }),

  computed: {
    ...mapGetters({
      accountOptions: 'credentials/accountOptions',
    }),
    code() {
      return this.list[0]?.code
    },
    headers() {
      return headersGenerator(this)
    },
    config() {
      return this.list[0]?.config || {}
    },
    fields() {
      const fields = { ...FIELDS }
      ;(CUSTOM_FIELDS[this.code] || []).forEach((el) => {
        fields[el.key] = el
      })
      return Object.values(fields)
    },
  },

  mounted() {
    this.list = this.logics.map((logic, index) => {
      const _cloned = clonedeep(logic)
      _cloned.index = index
      return _cloned
    })
  },

  methods: {
    async save(event) {
      try {
        const payload = this.list.map((logic) => {
          const _logic = {
            _id: logic._id,
            credential: logic.credential._id,
            enable: logic.enable,
            name: logic.name,
            status: logic.status,
            memo: logic.memo || '',
            config: clonedeep(logic.config),
          }
          ;['reEntrySpan', 'orderSpan'].map((key) => {
            if (_logic.config[key]) {
              _logic.config[key] = _logic.config[key] * ONE_MINUTE
            }
          })
          return _logic
        })
        await this.$http.put('/api/logics/batch', payload)
        this.$toast.success(this.$t('logicModal.success'))
        this.$$closeModal(event)
      } catch (err) {
        this.$toast.error(this.$t('logicModal.fail'))
      }
    },
    setConfigValue(index, prop, value) {
      if (this.list.length > 1 && this.isBatchEdit) {
        this.list.forEach((logic) => {
          logic.config[prop] = value
        })
      } else {
        this.list[index].config[prop] = value
      }
    },
    setValue(index, prop, value) {
      if (this.list.length > 1 && this.isBatchEdit) {
        this.list.forEach((logic) => {
          logic[prop] = value
        })
      } else {
        this.list[index][prop] = value
      }
    },
    configs() {
      const orders = DISPLAY_ORDERS[this.code] || []
      return Object.keys(this.config || {})
        .sort((a, b) => (orders[a] || 0) - (orders[b] || 0))
        .map((key) => ({ key, field: this.configField(key) }))
        .filter((config) => config.field)
    },
    configField(key) {
      const config = this.fields.find((item) => item.key === key)
      return config?.hidden ? null : config
    },
  },
}
</script>
