import { formatUSD } from '@/utils/string/index.js'

export default {
  chart: {
    width: 900,
  },
  legend: {
    enabled: true,
  },
  lang: {
    noData: 'No trades',
  },
  rangeSelector: {
    enabled: false,
  },
  title: { text: 'Profit Loss' },
  subtitle: { text: '' },
  xAxis: [
    {
      type: 'datetime',
    },
  ],
  yAxis: [
    {
      visible: true,
      opposite: false,
      title: { text: 'Earn' },
      labels: {
        formatter: function () {
          return formatUSD(this.value)
        },
      },
    },
    {
      visible: true,
      opposite: true,
      title: { text: 'Total' },
      labels: {
        formatter: function () {
          return formatUSD(this.value)
        },
      },
    },
  ],
  series: [
    {
      name: 'Earn',
      color: '#244eb8',
      type: 'column',
      data: [],
      yAxis: 0,
      tooltip: { valuePrefix: '$ ' },
    },
    {
      name: 'Total Profit',
      color: '#5eac0d',
      data: [],
      type: 'spline',
      yAxis: 1,
      tooltip: { valuePrefix: '$ ' },
    },
  ],
}

