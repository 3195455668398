<template>
  <div class="max-w-none w-full md:w-4/6" v-if="ready">
    <div class="w-full flex justify-center flex-col">
      <div class="font-semibold">
        <div class="flex">
          <div>Name:&nbsp;</div>
          <p class="text-gray-600">{{ logic.name }}</p>
        </div>
        <div class="flex">
          <div>Exchange:&nbsp;</div>
          <p class="text-gray-600">
            {{ logic.credential?.exchange?.toUpperCase() }}
          </p>
        </div>
        <div class="flex">
          <div>Account:&nbsp;</div>
          <p class="text-gray-600">
            {{ logic.credential?.name }}
          </p>
        </div>
      </div>

      <chart :options="option" constructor-type="stockChart" class="mx-auto" />

      <div class="flex mt-4 w-full justify-end"></div>
    </div>
  </div>
</template>

<script>
import cloneDeep from 'lodash.clonedeep'
import { Chart } from 'highcharts-vue'
import _option from './_options.js'

export default {
  name: 'LogicChart',

  components: {
    Chart,
  },

  props: {
    logic: {
      type: Object,
      required: true,
    },
  },

  data: () => ({
    ready: false,
    option: {},
  }),

  created() {
    this.option = cloneDeep(_option)
  },

  mounted() {
    this.fetchOrders()
  },

  methods: {
    async fetchOrders() {
      const path = `/api/v2/logic-charts/${this.logic._id}`
      // TODO: timeout
      let profits = global.CACHES[path]
      if (!profits) {
        profits = await this.$http.get(path)
        profits.sort((a, b) => a[1] - b[1])
        global.CACHES[path] = profits
      }
      let total = 0
      profits.forEach(([pf, ts]) => {
        total = _round(total + pf)
        this.option.series[0].data.push([ts, pf])
        this.option.series[1].data.push([ts, total])
      })
      this.ready = true
    },
  },
}

function _round(value) {
  return Math.round(value * 1000) / 1000
}
</script>
