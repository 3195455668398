export default {
  chart: {
    width: 900,
  },
  legend: {
    enabled: true,
  },
  lang: {
    noData: 'No errors',
  },
  rangeSelector: {
    enabled: false,
  },
  title: { text: 'Errors' },
  subtitle: { text: '' },
  xAxis: [
    {
      type: 'datetime',
    },
  ],
  yAxis: [
    {
      visible: true,
      opposite: false,
      title: { text: 'Order Volume' },
    },
  ],
  tooltip: {
    formatter: function () {
      const style = ''
      // const style = `color: ${this.y > 0 ? 'green' : 'red'};`
      return `
      <div>
        <b style="">Error:</b>
        <br/>
        <span style="${style}">
          Side: ${this.y > 0 ? 'BUY' : 'SELL'}
        </span>
        <span style="${style}">
          Volume: ${Math.abs(this.y)} ${this.y < 10 ? 'BTC' : 'USD'}
        </span>
        <br/>
        <span style="${style}">
          Time: ${new Date(this.x).toLocaleString()}
        </span>
      </div>
        `
    },
  },
  series: [
    {
      name: 'Error',
      color: '#e84e33',
      marker: {
        symbol: 'circle',
      },
      type: 'scatter',
      data: [],
      yAxis: 0,
    },
  ],
}
