<template>
  <div>
    <d-btn @click="click" :color="color">
      <d-icon name="chart-line" />
    </d-btn>
  </div>
</template>

<script>
export default {
  name: 'chart-btn',

  props: {
    color: String,
  },

  methods: {
    click($event) {
      this.$emit('click', $event)
    },
  },
}
</script>
