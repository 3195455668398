<template>
  <div>
    <div v-if="tooltip" :data-tip="tooltip" class="tooltip">
      <d-labeled-input
        v-if="isNumberOrText"
        :label="label"
        :disabled="disabled"
        v-model="_value"
      />
      <d-labeled-select
        v-else
        :label="label"
        :disabled="disabled"
        :items="items"
        v-model="_value"
      />
    </div>
    <div v-else>
      <d-labeled-input
        v-if="isNumberOrText"
        outlined
        dense
        :label="label"
        :disabled="disabled"
        v-model="_value"
      />
      <d-labeled-select
        v-else
        outlined
        dense
        :label="label"
        :items="items"
        :disabled="disabled"
        v-model="_value"
      />
    </div>
  </div>
</template>

<script>
import { localeStringToNumber } from '@/utils/string/index.js'

const NUMBER = 'number',
  TEXT = 'text',
  SELECT = 'select'

export default {
  name: 'flexible-input',

  props: {
    value: {},
    items: {}, // use for select type
    inputType: {
      type: String,
      default: 'text',
      validator: (value) => {
        return [NUMBER, TEXT, SELECT].includes(value)
      },
    },
    label: {
      type: String,
      default: '',
    },
    tooltip: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    isNumberOrText() {
      return [NUMBER, TEXT].includes(this.inputType)
    },
    _value: {
      get() {
        // If type value is number, make it readable.
        if (this.inputType === NUMBER) {
          return Number(this.value).toLocaleString()
        }
        return this.value
      },
      set(val) {
        if (this.inputType === NUMBER) {
          val = localeStringToNumber(val)
        }
        this.$emit('input', val)
      },
    },
  },
}
</script>
