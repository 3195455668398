<template>
  <div
    class="h-5/6 overflow-y-scroll max-w-full w-full md:w-5/6 flex flex-col"
    v-if="input"
  >
    <div class="flex items-center justify-between">
      <span class="font-medium text-lg">{{ $t('Logic Detail') }}</span>
      <div class="inline-flex space-x-2 m-2">
        <d-btn color="primary" @click="save">
          {{ this.$t('Save') }}
        </d-btn>
        <d-btn @click="$$closeModal"> Close </d-btn>
      </div>
    </div>

    <div class="mt-2 flex-start space-x-2 mb-4">
      <label class="label font-bold px-2 w-20">
        <span class="label-text">{{ $t('Logic Name') }}</span>
      </label>
      <d-input class="w-full" v-model="input.name" />
    </div>

    <div class="flex-grow overflow-y-scroll border p-2">
      <div class="flex mt-4 w-full">
        <div class="form-control w-1/2">
          <label class="label">
            <span class="label-text font-bold">Account</span>
          </label>
          <div class="badge badge-outline badge-primary badge-lg">
            {{ input.credential.name }}
          </div>
        </div>

        <div class="form-control w-1/2">
          <label class="label">
            <span class="label-text font-bold">{{ $t('Status') }}</span>
          </label>
          <input
            type="checkbox"
            checked="checked"
            class="toggle border-solid"
            v-model="input.enable"
          />
        </div>
      </div>

      <div class="mt-4 flex flex-col">
        <label class="label">
          <span class="label-text font-bold">{{ $t('Config') }}</span>
        </label>

        <div class="grid grid-cols-2 gap-1">
          <div class="w-1/2" v-for="{ key, field } in configs" :key="key">
            <flexible-input
              :input-type="field.type"
              :label="label(key, field) + ':'"
              :disabled="field.disabled"
              :items="field.items"
              :tooltip="field.description"
              v-model="input.config[key]"
            />
          </div>
        </div>
      </div>
      <div class="form-control">
        <label class="label">
          <span class="label-text font-bold">Memo</span>
        </label>
        <textarea
          class="textarea h-24 border-solid textarea-bordered"
          placeholder="Memo"
          v-model="input.memo"
        />
      </div>
    </div>
  </div>
</template>

<script>
import clonedeep from 'lodash.clonedeep'
import {
  FIELDS,
  CUSTOM_FIELDS,
  DISPLAY_ORDERS,
} from '@/common/logic-configs.js'
import { ONE_MINUTE } from '@/common/constants.js'
import { isCoinBase } from '@/utils/exchange'
import FlexibleInput from '@/components/daisy/molecules/flexible-input/index.vue'

export default {
  name: 'logic-modal',

  components: { FlexibleInput },

  props: {
    logic: {
      type: Object,
      default: () => ({}),
    },
  },

  data: () => ({
    input: null,
  }),

  computed: {
    configs() {
      const orders = DISPLAY_ORDERS[this.input.code] || []
      return Object.keys(this.input.config || {})
        .sort((a, b) => (orders[a] || 0) - (orders[b] || 0))
        .map((key) => ({ key, field: this.configField(key) }))
        .filter((config) => config.field)
    },
    fields() {
      const fields = { ...FIELDS }
      ;(CUSTOM_FIELDS[this.input.code] || []).forEach((el) => {
        fields[el.key] = el
      })
      return Object.values(fields)
    },
  },

  mounted() {
    this.input = clonedeep(this.logic)
  },

  methods: {
    label(key, field) {
      const exchange = this.logic.credential.exchange
      const isBtc = isCoinBase(exchange)
      const map = isBtc ? { volume: 'Entry lot (in Coin)' } : {}
      return map[key] || field.label
    },
    configField(key) {
      if (key === 'productId' && this.input.credential.exchange !== 'bitcopy') {
        return null
      }
      const config = this.fields.find((item) => item.key === key)
      return config?.hidden ? null : config
    },
    async save(event) {
      try {
        const payload = clonedeep(this.input)
        ;['reEntrySpan', 'orderSpan'].map((key) => {
          if (payload.config[key]) {
            payload.config[key] = payload.config[key] * ONE_MINUTE
          }
        })
        await this.$http.put(`/api/v2/logics/${this.input._id}`, payload)
        this.$toast.success(this.$t('logicModal.success'))
        this.$$closeModal(event)
      } catch (err) {
        global.log(err)
        this.$toast.error(this.$t('logicModal.fail'))
      }
    },
  },
}
</script>
