<template>
  <div class="dropdown dropdown-end">
    <label tabindex="0" circle outline size="xs">
      <d-icon
        name="dots"
        size="sm"
        class="border border-solid rounded-full hover:bg-gray-200"
      />
    </label>
    <ul
      tabindex="0"
      class="p-2 shadow menu dropdown-content bg-base-100 rounded-box w-44"
    >
      <div class="menu-item" @click.stop="clone">
        <d-icon name="copy" />
        <span class="ml-2">{{ $t('Clone') }}</span>
      </div>
      <div class="menu-item" @click.stop="detail">
        <d-icon name="pencil" />
        <span class="ml-2">{{ $t('Detail') }}</span>
      </div>
      <div class="menu-item" @click.stop="toggleHidden" v-if="!logic.enable">
        <d-icon :name="logic.hidden ? 'eye' : 'eye-off'" />
        <span class="ml-2">{{
          `${logic.hidden ? $t('Show') : $t('Hide')}`
        }}</span>
      </div>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'item-action',

  props: {
    logic: {
      type: Object,
      default: () => ({}),
    },
    clone: {
      type: Function,
    },
    detail: {
      type: Function,
    },
    toggleHidden: {
      type: Function,
    },
  },
}
</script>

<style scoped lang="scss">
.menu-item {
  @apply flex;
  @apply flex-row;
  @apply p-2;
  @apply hover:bg-gray-200;
  @apply rounded-md;
  @apply cursor-pointer;
}
</style>
